.university-profile {
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.profile-container {
  margin-top: 2rem;
}

.overall-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.rating-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.university-profile {
overflow-x: hidden; /* Prevents horizontal scroll */
padding: 1rem;
}

.profile-container {
max-width: 1200px;
margin: 0 auto;
}

.rating-section {
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 1rem;
}

.ratings-wrapper {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
gap: 1rem;
}


.highest-lowest-container {
  display: flex;
  gap: 2rem;
}
.overall-rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.overall-rating-badge {
  font-size: 3rem;
  font-weight: bold;
  padding: 0.5rem 1rem; /* Adds padding around the text */
  border-radius: 12px;
  display: inline-block;
}

.overall-rating-label {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: #6b7280; /* Gray color for the label */
}



.cumulative-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
}

.category-name {
  font-weight: bold;
  font-size: 1rem;
}

.rating-badge {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 1.1rem;
  color: white;
}

.toggle-button {
  padding: 0.5rem 1rem;
  background-color: #004080;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #003060;
}

.ratings-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

@media (max-width: 768px) {
  .highest-lowest-container {
    flex-direction: column;
    gap: 1rem;
  }

  .ratings-wrapper {
    flex-direction: column;
  }

  .cumulative-rating {
    width: 100%;
  }
}
