/* Main container */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height */
    padding: 1rem;
    background-color: #f5f7fa;
  }
  
  /* Logo and Title */
  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .logo-image {
    width: 120px; /* Logo size */
    height: auto;
    margin-bottom: 1rem;
  }
  
  .website-title {
    font-size: 2rem;
    font-weight: bold;
    color: #004080;
    text-align: center;
  }
  
  /* Login content */
  .login-content {
    background-color: white;
    padding: 2rem 3rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .login-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  /* Google login button */
  .google-login-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .google-login-wrapper button {
    width: 100%;
    max-width: 280px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .login-content {
      padding: 1.5rem 2rem;
    }
  
    .login-title {
      font-size: 1.6rem;
    }
  
    .login-subtitle {
      font-size: 0.9rem;
    }
  }
  