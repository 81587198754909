/* General page styling */
.results-page {
    padding: 2rem;
    text-align: center;
  }
  
  .results-page h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .results-page h2 {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Grid layout for cards */
  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 1rem;
  }
  
  /* Styling for each card */
  .college-card {
    background-color: lightgray;
    border-radius: 12px;
    overflow: hidden;
    text-decoration: none;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    color: inherit;
    width: 100%;
    height:  100% ; /* Fixed height for consistent card size */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .college-card:hover {
    transform: scale(1.03);
  }
  
  /* Card content */
  .card-content {
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .college-name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .college-location {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  .college-rating {
    font-size: 1rem;
    color: #444;
    font-weight: bold;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .college-card {
      height: 250px; /* Smaller height for mobile */
    }
  }
  