.user-page-container {
  max-width: 850px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.user-page-title {
  font-size: 2.2rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}

.tabs-container {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  border-radius: 12px;
  padding: 0.5rem;
  margin-bottom: 2rem;
}

.tab-button {
  background-color: transparent;
  padding: 1rem 1.8rem;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-weight: 600;
  color: #333;
  border-radius: 8px;
}

.tab-button:hover {
  background-color: #e8efff;
  color: #004080;
  border-bottom: 2px solid #004080;
}

.active-tab {
  border-bottom: 3px solid #004080;
  background-color: #fff;
  color: #004080;
}

.profile-tab-content {
  margin-top: 2rem;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.user-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
}

.user-field label {
  font-weight: bold;
  font-size: 1.2rem;
  width: 25%;
}

.user-field span,
.user-input {
  font-size: 1.1rem;
  width: 70%;
  padding: 0.7rem;
}

.user-input {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: border 0.3s;
}

.user-input:focus {
  border-color: #004080;
  outline: none;
  background-color: #fff;
}

.user-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.edit-button,
.save-button,
.cancel-button {
  padding: 0.8rem 2rem;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.save-button {
  background-color: #22c55e;
  color: white;
  border: none;
}

.save-button:hover {
  background-color: #1a8f41;
}

.cancel-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
}

.cancel-button:hover {
  background-color: #e60000;
}

.edit-button {
  background-color: #004080;
  color: white;
  border: none;
}

.edit-button:hover {
  background-color: #003366;
}

/* Rating card styling */
/* General container styles */
.ratings-page-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box; /* Prevent padding/margin from affecting width */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Title styling */
.ratings-page-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
}

/* Tabs container styling */
.tabs-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 1rem; /* Add gap for better spacing */
  flex-wrap: wrap; /* Ensure tabs wrap on smaller screens */
}

/* Individual tab button styling */
.ratings-page-tab-button {
  padding: 0.8rem 1.2rem;
  border: none;
  background-color: #f8f9fa;
  cursor: pointer;
  font-weight: bold;
  flex-grow: 1; /* Allow buttons to evenly distribute */
  text-align: center; /* Center align the text */
}

.ratings-page-tab-button.active-tab {
  background-color: #004080;
  color: white;
}

/* Ratings cards container */
.ratings-page-ratings-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Individual rating card styling */
.ratings-page-rating-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* College header styling */
.ratings-page-college-header {
  margin-bottom: 1rem;
}

.ratings-page-college-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.ratings-page-college-location {
  font-size: 1rem;
  color: #666;
}

/* No ratings message styling */
.ratings-page-no-ratings-message {
  text-align: center;
  font-size: 1rem;
  color: #555;
}

/* Action buttons container */
.ratings-page-rating-actions {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

/* Edit button styles */
.ratings-page-edit-rating-button {
  background-color: #004080;
  color: white;
}

.ratings-page-edit-rating-button:hover {
  background-color: #003366;
}

/* Delete button styles */
.ratings-page-delete-rating-button {
  background-color: #d32f2f;
  color: white;
}

.ratings-page-delete-rating-button:hover {
  background-color: #b71c1c;
}

/* Cancel button styles */
.ratings-page-cancel-edit-button {
  background-color: #ff4d4d;
  color: white;
}

.ratings-page-cancel-edit-button:hover {
  background-color: #d32f2f;
}

/* Edit form wrapper */
.ratings-page-edit-form-wrapper {
  border-top: 1px solid #ddd;
  padding-top: 1rem;
  margin-top: 1rem;
}

/* Cancel edit button wrapper */
.ratings-page-cancel-edit-wrapper {
  text-align: right;
  margin-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tabs-container {
    flex-direction: column;
    gap: 1rem;
  }

  .ratings-page-rating-card {
    padding: 0.8rem;
  }

  .ratings-page-edit-rating-button,
  .ratings-page-delete-rating-button,
  .ratings-page-cancel-edit-button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }
}

@media (max-width: 480px) {
  .ratings-page-title {
    font-size: 1.5rem;
  }

  .ratings-page-tab-button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }

  .ratings-page-edit-rating-button,
  .ratings-page-delete-rating-button,
  .ratings-page-cancel-edit-button {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }
}

