.review-form {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rating-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.rating-column {
  flex: 1 1 calc(50% - 1rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rating-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.category-label {
  width: 40%;
  font-weight: bold;
}

.rating-bar-container {
  flex: 1;
}

.rating-bar {
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
}

.bar-segment {
  flex: 1;
  height: 20px;
  border-radius: 4px;
  background-color: #e5e7eb; /* Light grey background for unselected */
  transition: background-color 0.3s ease;
}

.bar-segment.filled {
  background-color: #22c55e; /* Default green for filled segments */
}

.bar-segment:hover {
  opacity: 0.9;
}

.center-label {
  text-align: center;
  font-size: 0.8rem;
  color: #6b7280;
  margin-top: 0.5rem;
}

.comment-box {
  width: 100%;
  height: 100px;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-top: 1rem;
}

.submit-button {
  width: 100%;
  padding: 0.8rem;
  background-color: #004080;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .rating-grid {
    flex-direction: column;
  }

  .rating-column {
    flex: 1 1 100%;
  }

  .rating-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .category-label {
    width: 100%;
    margin-bottom: 0.3rem;
  }

  .rating-bar-container {
    width: 92%;
  }

  .submit-button {
    padding: 1rem;
    font-size: 1rem;
  }
}
