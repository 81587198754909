/* General Styles */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

a:hover {
  color: #004080; /* Subtle hover effect for links */
}

button {
  border: none;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s ease;
}

button:hover {
  transform: scale(1.02);
}

/* Hero Section */
.hero-section {
  background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.9),
          rgba(0, 0, 0, 0.6)
      ),
      url('../assets/hero-background.jpg') center/cover no-repeat;
  color: #fff;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
  position: relative;
  z-index: 1; /* Ensure this section stays below the navbar and dropdown */
  overflow: hidden;
}
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1; /* Keep the background behind the content */
}

.hero-logo {
  max-width: 250px;
  max-height: 140px; /* Constrain the height */
  margin-bottom: 1rem;
  filter: brightness(0) invert(1); /* Makes the logo white */
  object-fit: contain; /* Ensures the logo stays within its box */
  margin-top: 1px;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #f1f1f1;
}

.cta-button {
  background-color: #004080;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #003366;
  transform: scale(1.05);
}

/* About Section */
.about-section {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #fff;
}

.about-content h2 {
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 1rem;
}

.about-content p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  color: #555;
}

/* Features Section */
.features-section {
  padding: 4rem 2rem;
  text-align: center;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.feature-card {
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  cursor: pointer;
}

.feature-card h3 {
  font-size: 1.5rem;
  color: #004080;
  margin-bottom: 0.5rem;
}

.feature-card p {
  font-size: 1rem;
  color: #555;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Call to Action Section */
.cta-section {
  background-color: #004080;
  color: #fff;
  text-align: center;
  padding: 4rem 2rem;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-button {
  background-color: #facc15;
  color: #333;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #eab308;
  transform: scale(1.05);
}

/* Footer */
.footer {
  background-color: #f9f9f9;
  padding: 2rem 1rem;
  text-align: center;
  color: #555;
  font-size: 0.9rem;
}
.navbar {
  position: relative; /* Ensure the navbar stays above all sections */
  z-index: 10; /* Higher z-index than the hero section */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 20; /* Ensure dropdown stays above everything, including the hero section */
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
      font-size: 2.5rem;
  }

  .hero-subtitle {
      font-size: 1rem;
  }

  .hero-logo {
      max-width: 180px;
      max-height: 120px; /* Adjust for smaller screens */
  }

  .features-grid {
      grid-template-columns: 1fr;
  }

  .cta-button {
      width: 100%;
  }
}

@media (max-width: 480px) {
  .hero-logo {
      max-width: 150px;
      max-height: 140px; /* Further adjust for very small screens */
      margin-top: 10px;
  }

  .hero-title {
      font-size: 2rem;
  }

  .hero-subtitle {
      font-size: 0.9rem;
  }

  .cta-button {
      font-size: 0.9rem;
      padding: 0.8rem 1.5rem;
  }

}
