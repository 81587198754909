/* General Styles */
body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
  }
  
  h1,
  h2 {
    color: #004080;
    text-align: center;
  }
  
  p {
    margin: 1rem 0;
    color: #555;
    font-size: 1.1rem;
    line-height: 1.8;
    max-width: 800px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Header Section */
  .about-header {
    background: linear-gradient(to bottom, #e6f1ff, #f9f9f9);
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .header-logo {
    max-width: 150px;
    margin-bottom: 1.5rem;
  }
  
  .about-header h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .about-header p {
    font-size: 1.2rem;
    color: #444;
  }
  
  /* About Website Section */
  .about-website {
    padding: 4rem 2rem;
    background-color: #fff;
  }
  
  .about-website h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .about-website p {
    max-width: 800px;
    margin-bottom: 1.5rem;
    color: #666;
    font-size: 1.1rem;
  }
  
  /* Developer Section */
  .developer-section {
    background: linear-gradient(to bottom, #f9f9f9, #e6f1ff);
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .developer-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .developer-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .developer-info {
    max-width: 600px;
    text-align: left;
  }
  
  .developer-info p {
    color: #555;
    line-height: 1.6;
  }
  
  .developer-info strong {
    color: #004080;
  }
  
  /* CTA Section */
  .cta-section {
    background-color: #004080;
    color: #fff;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .cta-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #fbfbfb;

  }
  
  .cta-section p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #fbfbfb;
  }
  
  .cta-button {
    background-color: #facc15;
    color: #333;
    padding: 0.8rem 2rem;
    font-size: 1.1rem;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #eab308;
    transform: scale(1.05);
  }
  

  
  /* Responsive Design */
  @media (max-width: 768px) {
    h1,
    h2 {
      font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  
    .developer-photo {
      width: 120px;
      height: 120px;
    }
  
    .cta-button {
      width: 100%;
      padding: 1rem;
      font-size: 1rem;
    }
  }
  