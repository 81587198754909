.review-card {
  background-color: #ebeff3;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
}

.comment-section {
  text-align: center;
  margin-bottom: 1rem;
}

.overall-rating-badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.comment-text {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #4b5563;
}

/* Layout for ratings */
.ratings-container {
  display: flex;
  gap: 1rem; /* Spacing between the columns */
}

.ratings-column {
  flex: 1; /* Each column takes up equal width */
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  gap: 0.8rem; /* Space between rows */
}

.rating-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.category-icon {
  font-size: 1.2rem;
}

.category-label {
  font-size: 1rem;
  font-weight: bold;
}

.rating-bar-container {
  flex-grow: 1;
  margin: 0 0.8rem;
  background-color: #e5e7eb;
  border-radius: 6px;
  overflow: hidden;
}

.rating-bar-value {
  height: 15px;
  border-radius: 5px 0 0 5px;
}

.rating-text {
  font-size: 0.9rem;
  font-weight: bold;
}

/* Footer styles */
.footer-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.review-date {
  font-size: 0.8rem;
  color: #6b7280;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ratings-container {
    flex-direction: column;
  }

  .rating-row {
    justify-content: flex-start;
    gap: 1rem;
  }
}
